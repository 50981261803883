'use strict';

(function () {

	var cookie = localStorage.getItem('hideCookieBanner');
	var consentCookie = localStorage.getItem('userConsent');
	var cookieAlert = document.querySelector('.cookie-alert');
	var cookieBtnAccept = document.querySelector('.cookie-alert__btn--accept');
	var cookieBtnDecline = document.querySelector('.cookie-alert__btn--decline');

	// if local storage key isn't found, set it to false
	if (cookie === null) {
		localStorage.setItem('hideCookieBanner', 'false');
	}

	if (consentCookie === null) {
		localStorage.setItem('userConsent', 'accepted');
	}

	// If key is false or not set, show banner
	if(cookieAlert) { 
		if (cookie === 'false' || cookie === null) {
			cookieAlert.classList.add('is-visible');
		}

		// Hide banner and set local storage key on click
		cookieBtnAccept.addEventListener('click', function (event) {
			localStorage.setItem('hideCookieBanner', 'true');
			localStorage.setItem("userConsent", "accepted");
			cookieAlert.classList.remove('is-visible');
		});

		cookieBtnDecline.addEventListener('click', function (event) {
			localStorage.setItem('hideCookieBanner', 'true');
			localStorage.setItem("userConsent", "declined");
			cookieAlert.classList.remove('is-visible');
		});
	}

}());