import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){

		new Swiper('.case-studies-swiper', {
			modules: [Navigation, Scrollbar],
			slidesPerView: "1",
			loop: false,
			autoHeight: true,
			spaceBetween: 20,
			scrollbar: {
				el: ".swiper-scrollbar",
				hide: false,
				draggable: true,
				snapOnRelease: true,
			},
			navigation: {
				nextEl: '.case-studies-swiper-wrap .swiper-button-next',
				prevEl: '.case-studies-swiper-wrap .swiper-button-prev',
			},
			breakpoints: {
				800: {
					slidesPerView: "2",
					spaceBetween: 35,
				},
				1024: {
					slidesPerView: "3",
					spaceBetween: 50,
				},
			},
		});

		new Swiper('.team-swiper', {
			modules: [Navigation],
			slidesPerView: "1",
			loop: true,
			spaceBetween: 20,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		new Swiper('.testimonials-swiper', {
			modules: [Navigation],
			slidesPerView: "1",
			loop: true,
			spaceBetween: 20,
			navigation: {
				nextEl: '.testimonials-swiper-wrap .swiper-button-next',
				prevEl: '.testimonials-swiper-wrap .swiper-button-prev',
			},
			breakpoints: {
				1024: {
					slidesPerView: "2",
					spaceBetween: 30,
				},
			},
		});
	}
});
